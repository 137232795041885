var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-card',{attrs:{"body-style":{ padding: '24px 32px' },"bordered":false}},[_c('a-form',{attrs:{"form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',{attrs:{"label":"用户名","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_vm._v(" "+_vm._s(_vm.adminInfo.username)+" ")]),_c('a-form-item',{attrs:{"label":"管理员角色","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_vm._v(" "+_vm._s(_vm.adminInfo.admin_group.title)+" ")]),_c('a-form-item',{attrs:{"label":"昵称","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'nickname',
        {
          rules: [{ required: true, message: '昵称不能为空' }],
          initialValue: _vm.adminInfo.nickname
        },
      ]),expression:"[\n        'nickname',\n        {\n          rules: [{ required: true, message: '昵称不能为空' }],\n          initialValue: adminInfo.nickname\n        },\n      ]"}],attrs:{"name":"nickname","placeholder":"昵称"}})],1),_c('a-form-item',{attrs:{"label":"邮箱","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'email',
        {
          rules: [
            { type: 'email', required: true, message: '请输入邮箱地址' },
          ],
          initialValue: _vm.adminInfo.email
        }
      ]),expression:"[\n        'email',\n        {\n          rules: [\n            { type: 'email', required: true, message: '请输入邮箱地址' },\n          ],\n          initialValue: adminInfo.email\n        }\n      ]"}],attrs:{"type":"email","placeholder":"邮箱"}})],1),_c('a-form-item',{attrs:{"label":"密码","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'password',
        {
          rules: [
            {
              required: _vm.checkPass, message: '请输入密码'
            },
            {
              validator: (rules, value, callback) => { this.handleCheckPwd(rules, value, callback) }
            }
          ]
        }
      ]),expression:"[\n        'password',\n        {\n          rules: [\n            {\n              required: checkPass, message: '请输入密码'\n            },\n            {\n              validator: (rules, value, callback) => { this.handleCheckPwd(rules, value, callback) }\n            }\n          ]\n        }\n      ]"}],attrs:{"type":"password","placeholder":"密码"}})],1),_c('a-form-item',{attrs:{"label":"确认密码","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'pwd_confirm',
        {
          rules: [
            {
              required: _vm.checkPass, message: '请再次输入密码'
            },
            {
              validator: (rules, value, callback) => { this.handleCfmPwd(rules, value, callback) }
            }
          ]
        }
      ]),expression:"[\n        'pwd_confirm',\n        {\n          rules: [\n            {\n              required: checkPass, message: '请再次输入密码'\n            },\n            {\n              validator: (rules, value, callback) => { this.handleCfmPwd(rules, value, callback) }\n            }\n          ]\n        }\n      ]"}],attrs:{"type":"password","placeholder":"注：修改用户时，如果填了密码和确认密码，系统则默认是重置密码行为"}})],1),_c('a-form-item',{attrs:{"label":"电话号码","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'phone',
        {
          rules: [{ required: true, message: '请输入电话号码' }],
          initialValue: _vm.adminInfo.phone
        },
      ]),expression:"[\n        'phone',\n        {\n          rules: [{ required: true, message: '请输入电话号码' }],\n          initialValue: adminInfo.phone\n        },\n      ]"}],staticStyle:{"width":"100%"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(['pre_code', { initialValue: _vm.adminInfo.pre_code }]),expression:"['pre_code', { initialValue: adminInfo.pre_code }]"}],staticStyle:{"width":"70px"},attrs:{"slot":"addonBefore"},slot:"addonBefore"},[_c('a-select-option',{attrs:{"value":"86"}},[_vm._v(" +86 ")]),_c('a-select-option',{attrs:{"value":"87"}},[_vm._v(" +852 ")])],1)],1)],1),_c('a-form-item',{attrs:{"label":"头像链接","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'avatar',
        {
          initialValue: _vm.adminInfo.avatar
        }
      ]),expression:"[\n        'avatar',\n        {\n          initialValue: adminInfo.avatar\n        }\n      ]"}],attrs:{"placeholder":"avatar头像链接"}})],1),_c('a-form-item',{attrs:{"label":"语言","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'lang',
        {
          rules: [{ required: true, message: '请选择默认语言' }],
          initialValue: _vm.adminInfo.lang
        }
      ]),expression:"[\n        'lang',\n        {\n          rules: [{ required: true, message: '请选择默认语言' }],\n          initialValue: adminInfo.lang\n        }\n      ]"}],attrs:{"placeholder":"选择默认语言"}},[_c('a-select-option',{key:"zh-CN"},[_vm._v(" zh-CN ")]),_c('a-select-option',{key:"en-US"},[_vm._v(" en-US ")])],1)],1),_c('a-form-item',{attrs:{"label":"备注","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'desc',
        {
          initialValue: _vm.adminInfo.desc
        }
      ]),expression:"[\n        'desc',\n        {\n          initialValue: adminInfo.desc\n        }\n      ]"}],attrs:{"rows":"4","placeholder":"请输入管理员描述信息"}})],1),_c('a-form-item',{staticStyle:{"text-align":"center"},attrs:{"wrapperCol":{ span: 24 }}},[_c('a-button',{attrs:{"htmlType":"submit","loading":_vm.submitLoading,"type":"primary"}},[_vm._v("提交")]),_c('a-button',{staticStyle:{"margin-left":"8px"},on:{"click":_vm.resetFrom}},[_vm._v("重置")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }