<template>
  <a-card :body-style="{ padding: '24px 32px' }" :bordered="false">
    <a-form @submit="handleSubmit" :form="form">
      <a-form-item label="用户名" :labelCol="labelCol" :wrapperCol="wrapperCol">
        {{ adminInfo.username }}
      </a-form-item>
      <a-form-item label="管理员角色" :labelCol="labelCol" :wrapperCol="wrapperCol">
        {{ adminInfo.admin_group.title }}
      </a-form-item>
      <a-form-item label="昵称" :labelCol="labelCol" :wrapperCol="wrapperCol">
        <a-input v-decorator="[
          'nickname',
          {
            rules: [{ required: true, message: '昵称不能为空' }],
            initialValue: adminInfo.nickname
          },
        ]" name="nickname" placeholder="昵称" />
      </a-form-item>
      <a-form-item label="邮箱" :labelCol="labelCol" :wrapperCol="wrapperCol">
        <a-input v-decorator="[
          'email',
          {
            rules: [
              { type: 'email', required: true, message: '请输入邮箱地址' },
            ],
            initialValue: adminInfo.email
          }
        ]" type="email" placeholder="邮箱" />
      </a-form-item>
      <a-form-item label="密码" :labelCol="labelCol" :wrapperCol="wrapperCol">
        <a-input v-decorator="[
          'password',
          {
            rules: [
              {
                required: checkPass, message: '请输入密码'
              },
              {
                validator: (rules, value, callback) => { this.handleCheckPwd(rules, value, callback) }
              }
            ]
          }
        ]" type="password" placeholder="密码" />
      </a-form-item>
      <a-form-item label="确认密码" :labelCol="labelCol" :wrapperCol="wrapperCol">
        <a-input v-decorator="[
          'pwd_confirm',
          {
            rules: [
              {
                required: checkPass, message: '请再次输入密码'
              },
              {
                validator: (rules, value, callback) => { this.handleCfmPwd(rules, value, callback) }
              }
            ]
          }
        ]" type="password" placeholder="注：修改用户时，如果填了密码和确认密码，系统则默认是重置密码行为" />
      </a-form-item>
      <a-form-item label="电话号码" :labelCol="labelCol" :wrapperCol="wrapperCol">
        <a-input v-decorator="[
          'phone',
          {
            rules: [{ required: true, message: '请输入电话号码' }],
            initialValue: adminInfo.phone
          },
        ]" style="width: 100%">
          <a-select slot="addonBefore" v-decorator="['pre_code', { initialValue: adminInfo.pre_code }]"
            style="width: 70px">
            <a-select-option value="86">
              +86
            </a-select-option>
            <a-select-option value="87">
              +852
            </a-select-option>
          </a-select>
        </a-input>
      </a-form-item>
      <a-form-item label="头像链接" :labelCol="labelCol" :wrapperCol="wrapperCol">
        <a-input v-decorator="[
          'avatar',
          {
            initialValue: adminInfo.avatar
          }
        ]" placeholder="avatar头像链接" />
      </a-form-item>
      <a-form-item label="语言" :labelCol="labelCol" :wrapperCol="wrapperCol">
        <a-select v-decorator="[
          'lang',
          {
            rules: [{ required: true, message: '请选择默认语言' }],
            initialValue: adminInfo.lang
          }
        ]" placeholder="选择默认语言">
          <a-select-option key="zh-CN">
            zh-CN
          </a-select-option>
          <a-select-option key="en-US">
            en-US
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="备注" :labelCol="labelCol" :wrapperCol="wrapperCol">
        <a-textarea rows="4" placeholder="请输入管理员描述信息" v-decorator="[
          'desc',
          {
            initialValue: adminInfo.desc
          }
        ]" />
      </a-form-item>
      <a-form-item :wrapperCol="{ span: 24 }" style="text-align: center">
        <a-button htmlType="submit" :loading="submitLoading" type="primary">提交</a-button>
        <a-button style="margin-left: 8px" @click="resetFrom">重置</a-button>
      </a-form-item>
    </a-form>
  </a-card>
</template>

<script>
import { getInfo, accountUpdate } from '@/api/admin'

export default {
  name: 'Myaccount',
  data() {
    return {
      description: '我的账户页面',
      labelCol: {
        lg: { span: 7 },
        sm: { span: 7 }
      },
      wrapperCol: {
        lg: { span: 10 },
        sm: { span: 17 }
      },
      form: this.$form.createForm(this),
      adminInfo: {
        'admin_group': {
          title: ''
        }
      },
      adminInfoBack: {},
      checkPass: false,
      submitLoading: false
    }
  },
  created() {
    this.getAdminInfo()
  },
  methods: {
    getAdminInfo() {
      new Promise((resolve, reject) => {
        getInfo().then(response => {
          if (response.status === 'success') {
            resolve(response)
          } else {
            reject(response)
          }
        })
      }).then(res => {
        this.adminInfo = res.data.admin_info
        this.adminInfoBack = res.data.admin_info
        console.log(res)
      }).catch(error => {
        this.$notification.error({
          message: error.status,
          description: error.message
        })
      })
    },
    handleCheckPwd(rules, value, callback) {
      var cfmPwd = this.form.getFieldValue('pwd_confirm')
      if (cfmPwd && cfmPwd !== value) {
        callback(new Error('两次密码输入不一致'))
      } else {
        // Note: 必须总是返回一个 callback，否则 validateFieldsAndScroll 无法响应
        callback()
      }
    },
    handleCfmPwd(rules, value, callback) {
      var password = this.form.getFieldValue('password')
      if (password && password !== value) {
        callback(new Error('两次密码输入不一致'))
      } else {
        // Note: 必须总是返回一个 callback，否则 validateFieldsAndScroll 无法响应
        callback()
      }
    },
    handleSubmit(e) {
      e.preventDefault()
      this.form.validateFields((err, values) => {
        if (!err) {
          new Promise((resolve, reject) => {
            this.submitLoading = true
            accountUpdate(values).then(response => {
              if (response.status === 'success') {
                resolve(response)
              } else {
                reject(response)
              }
            })
          }).then(res => {
            this.$notification.success({
              message: res.status,
              description: res.message
            })
          }).catch(error => {
            this.$notification.error({
              message: '错误',
              description: error.message
            })
          }).finally(() => {
            this.submitLoading = false
          })
        }
      })
    },
    resetFrom() {
      this.adminInfo = this.adminInfoBack
    }
  }
}

</script>
